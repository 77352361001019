import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import  '@/assets/css/common.css'
import vueSwiper from 'vue-awesome-swiper'  //引入vue-awesome-swiper
import "swiper/swiper-bundle.css";  //引入样式
Vue.use(vueSwiper)  //使用插件


Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
