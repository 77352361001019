import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import("@/views/index/index.vue"),
    children: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: '/home',
        component: () => import("@/views/home/home.vue")
      },
      {
        path: '/solution',
        component: () => import("@/views/solution/solution.vue")
      },
      {
        path: '/service',
        component: () => import("@/views/service/service.vue")
      },
      {
        path: '/help',
        component: () => import("@/views/help/help.vue")
      },
      {
        path: '/hardware',
        component: () => import("@/views/hardware/hardware.vue")
      },
      {
        path: '/download',
        component: () => import("@/views/download/download.vue")
      },
      {
        path: '/join',
        component: () => import("@/views/join/join.vue")
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
